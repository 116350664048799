// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-tsx": () => import("/home/vsts/work/1/s/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-book-demo-tsx": () => import("/home/vsts/work/1/s/src/pages/book-demo.tsx" /* webpackChunkName: "component---src-pages-book-demo-tsx" */),
  "component---src-pages-contact-tsx": () => import("/home/vsts/work/1/s/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/vsts/work/1/s/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("/home/vsts/work/1/s/src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-science-tsx": () => import("/home/vsts/work/1/s/src/pages/science.tsx" /* webpackChunkName: "component---src-pages-science-tsx" */)
}

