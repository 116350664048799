module.exports = [{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K4VMRMR","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            pageType: window.pageType,\n          };\n        }"},"gtmAuth":"D-ZYmKsbNQj7PLBv6IImBg","gtmPreview":"env-1"},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-55124523-17","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"cookieDomain":"auto"},
    },{
      plugin: require('/home/vsts/work/1/s/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
