import React, { Component } from "react";

import "../../styles/ht-styles.scss";
import * as styles from "./Layout.module.scss";

export interface IContext {
  locale: string;
  name: string;
}

const LocaleContext = React.createContext<IContext | null>(null);

interface IProps {
  pageContext: IContext;
}

class Layout extends Component<IProps> {
  render() {
    const { children, pageContext } = this.props;
    return (
      <LocaleContext.Provider value={{ ...pageContext }}>
        <div className={styles.container}>
          <div className={styles.containerContent}>{children}</div>
        </div>
      </LocaleContext.Provider>
    );
  }
}

export { LocaleContext };
export default Layout;
